import { GetStaticProps, NextPage } from "next/types";
import {
  StoryblokContextData,
  getStoryBlokPageContext,
} from "../components/storyblok/StoryblokData";
import { DynamicPageWrap } from "../components/DynamicSBPage";

const NotFoundPage: NextPage<StoryblokContextData> = (
  contextData: StoryblokContextData
) => {
  const modifiedContext: StoryblokContextData = {
    ...contextData,
    error: { ...contextData.error, is404: true },
  };
  return <DynamicPageWrap {...modifiedContext} />;
};

const getStaticProps: GetStaticProps = async (props: any) => {
  const contextData: StoryblokContextData = await getStoryBlokPageContext({
    params: {
      slug: ["404page"],
    },

    ...props,

    slug: "404page",
  });

  return {
    props: {
      contextData,
    },
    revalidate: 3600, // revalidate every hour
  };
};

export default NotFoundPage;
export { getStaticProps };
